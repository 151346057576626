.settings-view{
  overflow: hidden auto;
  padding: 2.6%;
  .label{
    color: #073C3C;
  }
  .settings-form{
    padding: 47px 0 0 4%;
    .form-input{
      margin-left: 2.5%;
      margin-top: 20px;
      .label{
        margin-right: 97px;
      }
      .select-group{
        margin-bottom: 0;
      }
      .input-group{
        margin-bottom: 0;
        color: #254343;
        .input-container{
          height: 43px;
        }
      }
    }
    .update{
      margin-top: 94px;
      padding: 9px 15px;
      height: 45px;
    }
  }
}