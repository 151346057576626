.app-menu {
    padding: 8px 0;
    min-width: 250px;
  
    .profile-row {
      padding: 0 15px;
      align-items: flex-start;
    }
  
    .menu-options-row {
      padding: 4px 0;
      justify-content: space-between;
    }
  
    .profile {
      color: #072C3C;
    }
  
    .logout {
      color: #FF3333;
    }
  
    .switch-to {
      align-items: flex-start;
      padding-top: 12px;
      color: #072C3C;
  
      .switch-btn {
        background-color: rgba($color: #238787, $alpha: 0.5);
        border-radius: 6px;
        padding: 8px 14px;
        margin-left: 16px;
        color: #072C3C;
  
        .logo {
          width: 40px;
          height: 40px;
          margin-bottom: 4px;
        }
      }
    }
  
    .menu-entry{
      height: 35px;
      padding: 6px 12px;
      text-decoration: none;
  
      &:nth-child(1){
        margin-bottom: 12px;
      }
  
      &.link{
        cursor: pointer;
      }
    }
  
    .mod-logo{
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }