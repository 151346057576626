@font-face {
  font-family: 'arcicon';
  src:  url('./assets/fonts/icomoon.eot?76y3sj');
  src:  url('./assets/fonts/icomoon.eot?76y3sj#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?76y3sj') format('truetype'),
    url('./assets/fonts/icomoon.woff?76y3sj') format('woff'),
    url('./assets/fonts/icomoon.svg?76y3sj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'arcicon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e909";
}
.icon-arrow-round-forward:before {
  content: "\e908";
}
.icon-calender:before {
  content: "\e906";
}
.icon-send:before {
  content: "\e907";
}
.icon-plus:before {
  content: "\e900";
}
.icon-attach-file:before {
  content: "\e902";
}
.icon-chavron-down:before {
  content: "\e903";
}
.icon-search:before {
  content: "\e904";
}
.icon-sort:before {
  content: "\e905";
}
.icon-pin:before {
  content: "\e901";
}
