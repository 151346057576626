.sources{
  overflow: hidden auto;
  padding: 24px;
  .source{
    background-color: #FFF;
    padding: 8px;
    position: relative;
    .label{
      color: #1177CC;
    }
    .logo{
      width: 60px;
      height: 60px;
      margin: 0 16px;
      object-fit: contain;
    }
    .tbl{
      width: 100%;
      td, th{
        border-bottom: none;
        padding: 12px 0 12px 12px ;
      }
      .cell, td  span, th  span{
        font-weight: inherit !important;
        color: #073C3C;
      }
    }
    .actions-row{
      position: absolute;
      bottom: 18px;
      right: 16px;
      .app-btn{
        color: #FFF;
        margin-left: 16px;
        text-transform: capitalize;
        padding: 5px 10px;
      }
    }
  }
}