@import 'variables';
@import "./font.scss";
@import "./icon.scss";

$primary : #9E0521;
$secondary : #E3CB5E;


.pr-border {
  border-color: $primary !important;
}

.bg-primary {
  background-color: $primary !important;
  color: #FFF !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.cr-prim {
  color: $primary !important;
}

.text-color-blue {
  color: $color-text-blue;
}

.col {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
}

.row {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-ctr {
  align-items: center;
  justify-content: center;
}

.start {
  align-items: flex-start;
}

.end {
  align-items: flex-end;
}

.space-btn {
  align-items: center;
  justify-content: space-between;
}

.space-end {
  align-items: center;
  justify-content: flex-end;
}

.h-start{
  justify-content: flex-start;
}
.h-btn{
  justify-content: space-between;
}

.btn-end{
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 16px;
  right: 16px;
}

.full {
  width: 100%;
  height: 100%;
}

.w-full {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-full {
  flex: 1;
  -ms-flex: 1;
}

.grow {
  flex-grow: 1;
}

a {
  text-decoration: none;
}

* {
  // color: #4f4f4f;
  box-sizing: border-box !important;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

input {
  outline: none;
  border: none;
}

.btn-add-plus {
  padding: 16px;
  border-radius: 8px;
  background-color: blue;

  .text {
    font-size: 14px;
    font-weight: bold;
    margin-right: 16px;
    color: #FFF;
  }
}

.navbar {
  position: relative;
  padding: 16px;
  align-items: center;

  .company {
    // position: absolute;
    // right: 16px;
    // top: 16px;
  }

  .arc-logo{
    width: 149px;
    object-fit: contain;
  }

  .items {
    padding: 0 70px;

    .item {
      margin-right: 55px;
    }
  }
}

.account-icon:hover {
  cursor: pointer;
}

.menu-pic {
  margin-left: -4px;
  margin-right: 8px;
}

.account-options {
  width: 100%;

  .company {
    margin-left: 45px;
    max-width: 200px;
    max-height: 40px;
  }

  .option {
    margin-left: 20px;
    cursor: pointer;
  }
}

.dialog {
  .dialog-paper-root {
    top:5%;
    left:-1%;
    max-width: 95%;
    min-height: 75%;
    max-height: 85%;
    overflow: scroll;
  }
}

.select-group {
  position: relative;
  margin-bottom: 16px;

  .input-group {
    margin-bottom: 0;
  }
}

.auto-paper{
  min-width: max-content
}

.dropdown-menu {
  .szh-menu{
    max-height: 175px !important;
    overflow: hidden auto;
  }

  .szh-menu__item {
    @extend .h5;
    max-width: 50vw;
  }

  .szh-menu__item--hover {
    color: #1177CC;
    background-color: transparent;
  }
}

.dropdown-menu>.szh-menu__item {
  padding-right: 3.5rem !important;
}

.input-group {
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
  position: relative;

  label {
    font-size: 1.4rem;
    color: #254343;
    margin: 0 4px 4px 0;
  }

  .input-container {
    width: 100%;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #254343;
    background-color: white;
    resize: none;
    min-height: 40px;

    &.disabled {
      background-color: rgba(112, 112, 112, 0.1);
      cursor: not-allowed;
    }
  }

  .dropdown-input{
    flex: 1;
  }

  textarea,
  input {
    resize: none;
    background-color: transparent;
    border: none;
    outline: none;
    font: inherit !important;
    width: 90%;
    color: #254343;
    // @include placeholder {
    //   color: #868686;
    //   font-size: 1.4rem;
    // }
  }

  .max-len {
    width: 100%;
    text-align: right;
    margin: 1px 4px 0 0;
  }

  .MuiInputAdornment-root,
  .caret {
    // position: absolute;
    // right: 16px;
    // top: 50%;
    font-size: 8px;
    color: #1177CC;
  }

  .MuiInputAdornment-root {
    bottom: 16px;
  }

  .hint {
    font-size: 1.6rem;
    color: #254343;
    margin: 2px 0;
  }

  .date-btn {
    position: relative;
    min-width: 130px;
    background-color: transparent;
    color: #1177CC;
    font: inherit;

    .MuiInputAdornment-root {
      right: 0;
      margin-left: 16px;
      bottom: 50%;

      svg {
        fill: #1177CC;
      }

      .MuiSvgIcon-root {
        font-size: 2.2rem !important;
      }
    }

    input{
      max-width: 190px;
      margin-left: 16px;
    }

    span {
      font: inherit;
    }
  }

  &.date {
    .MuiSvgIcon-root {
      font-size: 2.2rem !important;
    }
  }

  .two-level-btn-action {
    background-color: transparent;
    flex: 1;
    text-align: left;
    color: #254343;
    opacity: 0.7;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.link-btn {
  color: #1177CC;
  padding: 8px;
  background-color: transparent;

  &.disabled {
    color: #707070;
    cursor: not-allowed;
  }
}

.btn-text {
  color: #FFF;
  text-transform: capitalize;
}

.add-btn {
  .btn-text {
    margin-right: 8px; //15px
    // font:inherit;
    font:-webkit-small-control;
  }

  .icon-plus {
    font-size: 15px; //earlier 18px
  }
}

.btn-load {
  .btn-text {
    margin-right: 6px;
  }

  circle {
    stroke: #FFF;
  }
}

.priority,
.status {
  padding: 4px 8px;
  border-radius: 5px;
  height: 25px;
  width: fit-content;
  // border: 1px solid #707070;
  color: #073C3C;
  text-transform: capitalize;
  background-color: #FFFF88;
}

.priority {
  &.critical {
    background-color: #CC0303;
    color: #F7F7F7;
  }

  &.high {
    background-color: #FC4343;
    color: #F7F7F7;
  }

  &.low {
    background-color: #FCFC43;
    color: #030303;
  }

  &.minimal {
    background-color: #FCFCCC;
    color: #030303;
  }

  &.none {
    background-color: #878787;
    color: #F7F7F7;
  }

  &.medium,
  &.defualt {
    background-color: #FCBC03;
    color: #030303
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.hide {
  display: none;
}

.main {
  overflow: hidden;
}

.jg-login {
  input {
    margin: 16px;
    padding: 16px;
  }
}

.error {
  width: 100%;
  text-align: left;
  margin: 1px 4px 0 0;
  color: red;
  // position: absolute;
  // bottom: 0;
  // height: 16px;
}

.task-home {
  padding: 0 24px;

  .link {
    text-transform: capitalize;
  }
}

.help-section {
  height: 100%;
  padding: 16px 32px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.16);
  margin-right: 4px;

  .title {
    font-size: 3rem;
    color: #238787;
  }

  .text {
    font-size: 2rem;
    color: #707070;
    margin-bottom: 16px;
  }
}

.breadcrumb {
  .link {
    font: inherit;
  }
}

.screen-title {
  margin: 12px 0;
  color: $color-text-green;
  font-weight: $font-weight-medium;
}

.blink_me {
  // animation: blinker 2s linear 2;
  // animation: blinker 10s linear infinite;
  animation: blinker 3s steps(5, start) infinite;
  -webkit-animation: blinker 3s steps(5, start) infinite;
}

.s-hide {
  opacity: 0;
}

.user-select {
  height: 30px;
  position: relative;
  button {
    padding: 0;
    color: inherit;
  }

  i {
    position: absolute;
    right: 2px;
  }

  span {
    color: inherit;
  }
}

.exo2 {
  font-family: 'Exo 2';
}

.roboto {
  font-family: $font-roboto;
}

.rbold {
  font-weight: $font-weight-bold;
}

.rsemi-bold {
  font-weight: $font-weight-semi-bold;
}

.rmedimum {
  font-weight: $font-weight-medium;
}

.wt-med {
  font-weight: $font-weight-medium;
}
.reg{
  font-weight: $font-weight-regular;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: #D8D8D8;
  text-transform: uppercase;

  &.small {
    width: 30px;
    height: 30px;
  }

  &.dark {
    background-color: #254343;
    color: #FFF;
  }
}

.arc-option {
  padding: 2px 14px 2px 24px;
  color: #9F9F9F;
  margin-right: 0;

  .option {
    border-bottom: 0.5px solid #9F9F9F;
    padding-top: 12px;
    padding-left: 0px !important;
    padding-bottom: 6px;
    margin-right: 0;

    .label {
      color: #9F9F9F;
      margin-right: 0;
    }
  }
}

.no-wrap{
  white-space: nowrap;
}

.MuiCalendarPicker-root {

  .MuiPickersDay-root,
  .MuiTypography-caption,
  .PrivatePickersFadeTransitionGroup-root,
  .PrivatePickersYear-yearButton {
    @extend .h7;
  }

  div[role="presentation"] .MuiIconButton-root .MuiSvgIcon-root,
  .MuiIconButton-edgeEnd .MuiSvgIcon-root,
  .MuiIconButton-edgeStart .MuiSvgIcon-root {
    @extend .h3;
  }

  .Mui-selected.MuiPickersDay-today,
  .MuiPickersDay-today {
    border-color: #FF3333 !important;
    background-color: #FF3333;
  }

  .Mui-selected.MuiPickersDay-today:hover,
  .MuiPickersDay-today:hover {
    border-color: #FF3333 !important;
    background-color: #FF3333;
  }
}

.alert-dialog {
  padding: 16px;
  max-width: 500px !important;

  .heading {
    color: #238787;
    margin-bottom: 12px;
  }

  .subheading {
    color: #072C3C;
  }

  .action {
    margin-top: 24px;
  }
}

.c00085{
  color: rgba($color: #000000, $alpha: 0.85);
}
.c1155CC {
  color: #1155CC;
}

// @keyframes blinker {
//   50% {
//     opacity: 0;
//   }
// }
@keyframes blinker {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blinker {
  to {
    visibility: hidden;
  }
}

.custom-scroll{
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1177CC;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #1177CC;
  }
}
.user-dropdown{
  max-width: 360px;
  min-width: 200px;
  background-color: #FFF;
  padding: 8px 0;
  z-index: 100000;
}
.mb1 {
  margin-bottom: 5px;
}
